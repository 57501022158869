<template>
  <li
    :class="['tree-node', {
               'tree-node--active': node.id === selectedNode?.id,
               'tree-node--folder': isFolder,
               'tree-node--folder-open': isFolder && open
             },
             `tree-node--level-${level || 0}`]"
  >
    <div
      class="tree-node__title-container"
      :tabindex="-1"
      :data-arrow-navigable-item="isNavigable || null"
      @keydown.enter.prevent="onNodeSelected(node)"
      @keydown.arrow-right.prevent="show"
      @keydown.arrow-left.prevent="hide"
    >
      <div
        v-if="isFolder"
        :class="['tree-node__chevron', {
          'tree-node__chevron--open': open
        }]"
        @click="toggle"
      />
      <div
        ref="domNodeTarget"
        class="tree-node__title"
        @click="onNodeSelected(node)"
      >
        {{ node.displayName || `All (${node.code})` }}
      </div>
    </div>
    <ul
      v-show="open"
      v-if="isFolder"
      class="tree-node__branch"
    >
      <tree-node
        v-for="descendant in node.descendants"
        :key="descendant.id"
        :node="descendant"
        :is-navigable="open"
        :selected-node="selectedNode"
        :level="level + 1"
        @nodeSelected="(theNode) => $emit('nodeSelected', theNode)"
      />
    </ul>
  </li>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
  toRefs,
  watch,
  nextTick,
} from 'vue';

import { useVisibility } from '@/composables/visibility';

export default {
  props: {
    node: {
      type: Object,
      required: true,
    },
    isNavigable: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    selectedNode: {
      type: Object,
      default: () => undefined,
    },
    level: {
      type: Number,
      default: 0,
    },
  },

  emits: ['nodeSelected'],

  setup(props, { emit }) {
    const {
      visible,
      toggle,
      show,
      hide,
    } = useVisibility();
    const open = computed(() => visible.value);

    const isRoot = computed(() => !props.node.ancestors);
    const isFolder = computed(() => props.node.descendants?.length > 0);

    const domNodeTarget = ref();

    const onNodeSelected = (node) => {
      emit('nodeSelected', node);
    };

    const { node, selectedNode } = toRefs(props);

    // eslint-disable-next-line arrow-body-style
    const isParentToSelected = () => {
      return (selectedNode
        && selectedNode?.value?.id !== node?.value?.id
        && selectedNode?.value?.id.includes(node?.value?.id));
    };

    if (props.expanded || isParentToSelected()) {
      show();
    }

    const isTheSelectedNode = () => selectedNode && selectedNode?.value.id === node?.value?.id;

    onMounted(() => {
      if (isTheSelectedNode()) {
        nextTick(() => domNodeTarget.value?.scrollIntoView({ block: 'center' }));
      }
    });

    watch(() => selectedNode.value, () => {
      if (props.expanded || isParentToSelected()) {
        show();
      } else {
        hide();
      }
      if (isTheSelectedNode()) {
        nextTick(() => domNodeTarget.value.scrollIntoView({ behavior: 'smooth', block: 'nearest' }));
      }
    });

    return {
      isRoot,
      isFolder,
      open,
      toggle,
      show,
      hide,
      onNodeSelected,
      domNodeTarget,
    };
  },
};
</script>

<style scoped>
.tree-node {
  background-color: var(--colour-bg-default);
  border-radius: 0.25rem;
}

.tree-node--folder-open {
  padding-bottom: 0;
}

.tree-node__title-container {
  display: flex;
  align-items: center;
  border-top: 0.0625rem solid var(--colour-bg-alternative);
}

.tree-node > .tree-node__title-container:hover {
  background-color: var(--colour-bg-alternative);
}

.tree-node > .tree-node__title-container:focus {
  outline: 0.0625rem solid var(--colour-border-default);
  outline-offset: -0.0625rem;
}

.tree-node--level-0 .tree-node__title-container > div:first-child {
  margin-left: calc(1.3125rem + (0 * 1.3125rem));
}

.tree-node--level-1 .tree-node__title-container > div:first-child {
  margin-left: calc(1.3125rem + (1 * 1.3125rem));
}

.tree-node--level-2 .tree-node__title-container > div:first-child {
  margin-left: calc(1.3125rem + (2 * 1.3125rem));
}

.tree-node--level-3 .tree-node__title-container > div:first-child {
  margin-left: calc(1.3125rem + (3 * 1.3125rem));
}

.tree-node--level-4 .tree-node__title-container > div:first-child {
  margin-left: calc(1.3125rem + (4 * 1.3125rem));
}

.tree-node--level-5 .tree-node__title-container > div:first-child {
  margin-left: calc(1.3125rem + (5 * 1.3125rem));
}

.tree-node__chevron {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  content: '';
  background: transparent;
  background-image: url("../../assets/svg/chevron-right.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: inherit;
}

.tree-node__chevron--open {
  height: 0.5rem;
  background-image: url("../../assets/svg/chevron-down.svg");
}

.tree-node__title {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.tree-node__title:hover {
  cursor: pointer;
}

.tree-node--folder .tree-node__title {
  display: flex;
  align-items: center;
}

.tree-node--active .tree-node__title {
  font-weight: bold;
}

.tree-node--active .tree-node__branch .tree-node__title {
  font-weight: normal;
}
</style>
