<template>
  <div class="preferences">
    <div class="preferences__navigation">
      <span
        v-if="hierarchies.length"
        class="preferences__column-label"
      >
        Preferences for
      </span>
      <hierarchies-dropdown
        v-if="hierarchies"
        class="preferences__navigation-margin"
        :hierarchies="hierarchies"
        :selected-hierarchy="hierarchy"
        @hierarchySelected="onHierarchySelected"
      />

      <span
        v-if="sortedHierarchyNodesDict && hierarchy"
        class="preferences__column-label"
      >
        Show values for
      </span>
      <hierarchy-nodes-search
        v-if="hierarchy && sortedHierarchyNodesDict"
        class="preferences__navigation-margin"
        :hierarchy-nodes="searchedForHierarchyNodes || []"
        :selected-hierarchy-node="hierarchyNode"
        @hierarchyNodeSelected="onHierarchyNodeClicked"
      />
      <tree
        v-if="sortedHierarchyNodesDict && hierarchyNode"
        v-arrow-navigable
        class="preferences__hierarchies-tree"
      >
        <tree-node
          :node="sortedHierarchyNodesDict"
          :expanded="true"
          :selected-node="hierarchyNode"
          @nodeSelected="onHierarchyNodeClicked"
        />
      </tree>
    </div>
    <div
      class="preferences__preference-info"
    >
      <div class="preferences__column-title">
        <span v-if="hierarchyNode">{{ hierarchyNode.ancestors
          ? hierarchyNode.displayName
          : `All (${hierarchyNode.code})` }}</span>
        <span v-else-if="hierarchies.length">
          All hierarchies
        </span>
      </div>
      <div
        class="preferences__search-wrapper"
      >
        <search
          v-if="emptySearchHasResults"
          v-model="preferenceSearchText"
          class="preferences__search"
          type="squared"
          @update:modelValue="$emit('update:modelValue', preferenceSearchText)"
        />
      </div>
      <div
        v-if="isLoadingInit"
        class="preferences__preference-skeletons"
      >
        <skeleton-group
          :skeletons="[
            { 'height': '5.3125rem' },
            { 'height': '5.3125rem' },
            { 'height': '5.3125rem' },
          ]"
        />
      </div>
      <preference-list
        v-else-if="preferenceValueList?.length"
        :is-loading="isLoadingMore"
        :preference-values="preferenceValueList"
        :preference-value-info="preferenceValueListInfo"
        @preferenceValueSelected="onPreferenceValueSelected"
      />
      <div
        v-else
      >
        No preferences found.
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import HierarchiesDropdown from '@/components/domain/hierarchies-dropdown.vue';
import HierarchyNodesSearch from '@/components/domain/hierarchy-nodes-search.vue';
import PreferenceList from '@/components/domain/preferences/preference-list.vue';
import Tree from '@/components/shared/tree.vue';
import TreeNode from '@/components/shared/tree-node.vue';
import Search from '@/components/shared/search.vue';
import SkeletonGroup from '@/components/shared/skeleton-group.vue';

import { useUtilsStore } from '@/store/utils';
import { useHierarchiesStore } from '@/store/hierarchy-management';
import { usePreferenceManagementStore } from '@/store/preference-management';

import { storeToRefs } from 'pinia';
import { useActorStore } from '@/store/actor';

export default {
  components: {
    HierarchiesDropdown,
    Tree,
    TreeNode,
    PreferenceList,
    HierarchyNodesSearch,
    Search,
    SkeletonGroup,
  },

  props: {
    // Controls search text
    modelValue: {
      type: String,
      default: '',
    },
    isLoadingInit: {
      type: Boolean,
      default: false,
    },
    isLoadingMore: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  async setup() {
    const router = useRouter();
    const route = useRoute();
    const { toHash } = useUtilsStore();

    const {
      preferenceValueList,
      preferenceValueListInfo,
    } = storeToRefs(usePreferenceManagementStore());

    const {
      hierarchy,
      hierarchies,
      hierarchyNode,
      sortedHierarchyNodesDict,
      searchedForHierarchyNodes,
    } = storeToRefs(useHierarchiesStore());

    const { product } = storeToRefs(useActorStore());

    const selectHierarchy = (theHierarchy) => {
      const query = theHierarchy
        ? {
          query: {
            ...route.query,
            hierarchyCode: theHierarchy,
            hierarchyNodeCode: 'ROOT',
            hierarchyNodeType: 'ROOTNODE',
          },
        }
        : {
          query: {
            productCode: route.query.productCode,
          },
        };
      router.replace(query);
    };

    const selectHierarchyNode = (theHierarchyNode) => {
      router.push({
        query: {
          ...route.query,
          hierarchyNode: theHierarchyNode.id,
          hierarchyNodeCode: theHierarchyNode.code,
          hierarchyNodeType: theHierarchyNode.type,
        },
      });
    };

    const onHierarchySelected = (theHierarchy) => {
      selectHierarchy(theHierarchy);
    };

    const onHierarchyNodeClicked = (theHierarchyNode) => {
      selectHierarchyNode(theHierarchyNode);
    };

    const onPreferenceValueSelected = (prefValue) => {
      router.push({
        query: {
          ...route.query,
          preferenceCode: prefValue.preferenceCode,
        },
      });
    };

    watch(() => sortedHierarchyNodesDict.value, (nodesDict) => {
      if (nodesDict && !route.query.hierarchyNode) {
        // Initially set the selected hierarchy node.
        selectHierarchyNode(nodesDict);
      }
    });

    const preferenceSearchText = ref();
    const emptySearchHasResults = ref(false);
    // We will only set this once so that search bar won't be hidden when there is an initial list
    watch(preferenceValueList, () => {
      if (preferenceValueList.value?.length) emptySearchHasResults.value = true;
    }, { immediate: true });

    return {
      toHash,
      product,
      preferenceValueList,
      preferenceValueListInfo,
      hierarchyNode,
      sortedHierarchyNodesDict,
      hierarchy,
      hierarchies,
      onHierarchySelected,
      onHierarchyNodeClicked,
      selectHierarchyNode,
      onPreferenceValueSelected,
      searchedForHierarchyNodes,
      preferenceSearchText,
      emptySearchHasResults,
    };
  },
};
</script>

<style scoped>
.preferences {
  display: flex;
  flex-direction: column;
}

.preferences__column-title {
  font-size: 1.5625rem;
  font-weight: bold;
}

.preferences__column-label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.preferences__navigation-margin {
  margin-bottom: 1rem;
}

.preferences :deep(.tree__nodes) {
  max-height: 15vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.preferences__search-wrapper {
  margin-bottom: 0.5rem;
}

@media (min-width: 46.375rem) {
  .preferences {
    flex-direction: row;
    align-items: flex-start;
    padding-top: 2.25rem;
    padding-right: 0 !important;
  }

  .preferences__navigation {
    display: flex;
    flex-direction: column;
    min-width: 22rem;
    margin-top: 0.5rem;
    margin-right: 2rem;
  }

  .preferences__preference-skeletons {
    flex-grow: 2;
  }

  .preferences__preference-info {
    flex-grow: 2;
  }

  .preferences :deep(.tree__nodes) {
    max-height: calc(100vh - 30rem);
  }

  .preferences__search-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0 2rem 0.5rem 0;
  }

  .preferences :deep(.table-cell) {
    width: 10%;
    max-width: 20%;
  }

  .preferences :deep(.table-cell:nth-child(1)) {
    width: 25%;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .preferences :deep(.table-cell:nth-child(2)) {
    width: 25%;
    max-width: 50%;
  }

  .preferences :deep(.table-cell:nth-child(3)) {
    width: 15%;
    max-width: 50%;
  }

  .preferences__search {
    width: 100%;
  }
}

@media (min-width: 64rem) {
  .preferences :deep(.tree__nodes) {
    max-height: calc(100vh - 35.5rem);
  }
}
</style>
