<template>
  <div
    class="pagination"
  >
    <div class="pagination__wrapper">
      <p
        class="pagination__text"
      >
        {{ shownItems }} of
        {{ total }}
      </p>
      <Button
        v-if="hasMore"
        :loading="isLoading"
        text="Show more"
        small
        type="primary"
        @click="onShowMoreClicked(shownItems)"
      />
    </div>
  </div>
</template>

<script>
import Button from '@ingka/button-vue';

export default {
  components: {
    Button,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    shownItems: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['showMoreClicked'],

  setup(props, { emit }) {
    const onShowMoreClicked = (theOffset) => {
      emit('showMoreClicked', theOffset);
    };

    return {
      onShowMoreClicked,
    };
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
}

.pagination__wrapper {
  display: flex;
  flex-direction: column;
}

.pagination__text {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: var(--colour-text-lightest);
  text-align: center;
}

.pagination__button {
  height: 2.5rem;
}

.pagination__button-label {
  font-size: 0.75rem;
}
</style>
