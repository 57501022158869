<template>
  <div
    v-for="skeleton in skeletons"
    :key="skeleton"
  >
    <Skeleton
      :width="skeleton.width"
      :height="skeleton.height"
      style="margin-bottom: 1rem"
    />
  </div>
</template>

<script>
import Skeleton from '@ingka/skeleton-vue';

export default {
  components: {
    Skeleton,
  },
  props: {
    skeletons: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
