<template>
  <div
    class="preference-list"
  >
    <list>
      <list-item
        v-for="prefValue in preferenceValues"
        :key="toHash(prefValue)"
        class="preference-list__item"
        tabindex="0"
        @click="onPreferenceValueSelected(prefValue)"
        @keydown.enter.prevent="onPreferenceValueSelected(prefValue)"
      >
        <div
          class="preference-list__name-description"
        >
          <div class="preference-list__item-name">
            <span>{{ prefValue.preferenceName }}</span>
          </div>

          <p class="preference-list__description">
            <span>{{ prefValue.preferenceDescription }}</span>
          </p>
        </div>

        <div
          v-if="getValidPreferenceValues(prefValue)"
          class="preference-list__item-value-info"
        >
          <div
            v-if="prefValue.preferenceType?.type === 'Single'"
            class="preference-list__item-value"
          >
            <div
              v-if="prefValue.preferenceType.baseType == 'Boolean'"
            >
              <span>{{ Boolean(Number(getValidPreferenceValue(prefValue)?.value)) }}</span>
            </div>
            <div
              v-else-if="prefValue.preferenceType.baseType === 'String'"
            >
              <span>
                {{ ((getValidPreferenceValue(prefValue)?.value)?.length &lt; 30)
                  ? getValidPreferenceValue(prefValue)?.value : '(String)' }}
              </span>
            </div>
            <div
              v-else-if="prefValue.preferenceType.baseType === 'Date'"
            >
              <span>
                {{ formatDate(getValidPreferenceValue(prefValue)?.value) }}
              </span>
            </div>
            <div
              v-else
            >
              <span>{{ getValidPreferenceValue(prefValue)?.value }}</span>
            </div>
          </div>
          <p
            v-else
            class="preference-list__item-value"
          >
            <span>({{ prefValue.preferenceType?.type.replace('"', '') }})</span>
          </p>
          <span class="preference-list__item-value-icons tooltip tooltip--leading">
            <span
              v-if="prefValue.overridden"
              class="preference-list__item-value-overridden tooltip__custom-trigger-wrapper"
            >
              <span
                class="tooltip__body"
                role="tooltip"
              >
                <span>Overridden by:</span>
                <div
                  class="tooltip__items-wrapper"
                >
                  <div
                    v-for="item in prefValue.overridden.slice(0, 20)"
                    :key="toHash(item)"
                  >
                    <div
                      class="tooltip__item"
                    >
                      <span>{{ item.hierarchyNodeName || 'Name not found' }}
                        ({{ item.hierarchyNodeCode }}) </span>
                    </div>
                  </div>
                  <div v-if="prefValue.overridden.length > 20">
                    <span>... too many items to show</span>
                  </div>
                </div>
              </span>
            </span>
            <span
              v-if="prefValue.futureStartTimes"
              class="preference-list__item-value-future-value tooltip__custom-trigger-wrapper"
            >
              <span
                class="tooltip__body"
                role="tooltip"
              >
                <span>Upcoming change(s):</span>
                <div
                  class="tooltip__items-wrapper"
                >
                  <div
                    v-for="item in prefValue.futureStartTimes.slice(0,20)"
                    :key="toHash(item)"
                    class="tooltip__item"
                  >
                    <span>{{ formatDate(item) }}</span>
                  </div>
                  <div v-if="prefValue.futureStartTimes.length > 20">
                    <span>... too many items to show</span>
                  </div>
                </div>
              </span>
            </span>
            <span
              v-if="prefValue.inherited"
              class="preference-list__item-value-inherited tooltip__custom-trigger-wrapper"
            >
              <span
                class="tooltip__body"
                role="tooltip"
              >
                <span>Inherited from:</span>
                <div
                  class="tooltip__items-wrapper"
                >
                  <div
                    class="tooltip__item"
                  >
                    <span>{{ prefValue.inherited.hierarchyNodeName }}
                      ({{ prefValue.inherited.hierarchyNodeCode }}) </span>
                  </div>
                </div>
              </span>
            </span>
          </span>
        </div>
      </list-item>
    </list>
    <pagination
      v-if="preferenceValueList.length"
      :is-loading="isLoading"
      :has-more="preferenceValueInfo.hasMore"
      :shown-items="preferenceValueList.length"
      :total="preferenceValueInfo.total"
      @showMoreClicked="onShowMoreClicked"
    />
  </div>
</template>

<script>
import List from '@/components/shared/list.vue';
import ListItem from '@/components/shared/list-item.vue';
import Pagination from '@/components/shared/pagination.vue';

import Icons from '@ingka/ssr-icon-vue/icons';

import { usePreferences } from '@/composables/system-management/preferences';
import { useUtilsStore } from '@/store/utils';

import { usePreferenceManagementStore } from '@/store/preference-management';

import { storeToRefs } from 'pinia';

export default {
  components: {
    List,
    ListItem,
    Pagination,
  },

  props: {
    preferenceValues: {
      type: Object,
      required: true,
    },
    preferenceValueInfo: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['preferenceValueSelected'],

  setup(props, { emit }) {
    const { storePreference } = usePreferences();
    const {
      storeOffset,
      resetOffset,
      toHash,
      formatDate,
    } = useUtilsStore();

    const {
      preferenceValueList,
    } = storeToRefs(usePreferenceManagementStore());

    const {
      getValidPreferenceValue,
      getValidPreferenceValues,
    } = usePreferenceManagementStore();

    const onPreferenceValueSelected = (prefValue) => {
      storePreference(prefValue);
      emit('preferenceValueSelected', prefValue);
    };

    const onShowMoreClicked = (offset) => {
      resetOffset();
      storeOffset(offset);
    };

    return {
      toHash,
      formatDate,
      getValidPreferenceValue,
      getValidPreferenceValues,
      onPreferenceValueSelected,
      preferenceValueList,
      onShowMoreClicked,
      ellipsis: Icons.ellipsis,
    };
  },
};
</script>

<style scoped>
.preference-list :deep(.list-item) {
  margin-bottom: 0.5625rem;
}

.preference-list__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.125rem 1.25rem;
  background-color: var(--colour-bg-alternative);
}

.preference-list__item:hover {
  text-decoration: none;
  cursor: pointer;
}

.preference-list__item-name {
  padding-bottom: 0.375rem;
  overflow-x: hidden;
  font-size: 0.8756rem;
  font-weight: bold;
  text-overflow: ellipsis;
}

.preference-list__item:hover .preference-list__item-name {
  text-decoration: underline;
}

.preference-list__item-value {
  display: flex;
  justify-content: right;
  font-weight: bold;
}

.preference-list__item-value-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
}

.preference-list__name-description {
  overflow: hidden;
  text-overflow: ellipsis;
}

.preference-list__description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.preference-list__item-value-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.preference-list__item-value-icons > * {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  content: '';
  background: transparent;
  background-repeat: no-repeat;
  background-size: contain;
}

.tooltip__body {
  position: absolute;
  transform: translateY(-1%) translateX(-100%);
}

.tooltip__body:hover {
  visibility: visible;
  opacity: 100;
}

.tooltip__item {
  max-width: 20rem;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip__items-wrapper {
  padding: 0 1rem 0 0;
}

.tooltip__custom-trigger-wrapper {
  position: relative;
}

.tooltip__custom-trigger-wrapper:hover .tooltip__body {
  visibility: visible;
  opacity: 100;
}

.preference-list__item-value-overridden {
  background-image: url("../../../assets/svg/overridden.svg");
}

.preference-list__item-value-future-value {
  background-image: url("../../../assets/svg/future-value.svg");
}

.preference-list__item-value-inherited {
  background-image: url("../../../assets/svg/inherited.svg");
}

@media (min-width: 46.375rem) {
  .tooltip__body {
    max-width: calc(100vw - 32rem);
  }

  .preference-list {
    max-height: calc(100vh - 30rem);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .preference-list__item {
    margin-right: 1rem;
  }

  .preference-list__name-description {
    max-width: calc(100vw - 38.5rem);
  }
}
</style>
