<template>
  <div class="tree">
    <ul
      class="tree__nodes"
      :class="{ 'tree__nodes--disabled': disabled }"
      data-arrow-navigable-trigger
      data-arrow-navigable-item
      tabindex="0"
    >
      <slot>
        <span class="tree__nodes--empty">
          No options available
        </span>
      </slot>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>
.tree__nodes {
  min-width: 22rem;
  border: 0.0625rem solid var(--colour-border-light);
  border-radius: 0.25rem;
}

.tree__nodes:focus {
  border: 0.0625rem solid var(--colour-border-default);
}

.tree__nodes > :deep(.tree-node:first-child) {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tree__nodes :deep(.tree-node:last-child) {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tree__nodes--empty {
  display: block;
  margin: 0.6875rem 0.875rem;
  color: var(--colour-disabled-ui);
}

.tree__nodes--disabled:focus {
  border-color: var(--colour-border-light);
}
</style>
