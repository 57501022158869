<template>
  <div
    class="hierarchy-node-search"
  >
    <search
      v-model="hierarchyNodeSearchText"
      :disabled="disabled"
      class="hierarchy-node-search__search"
      tabindex="0"
      type="squared"
      data-arrow-navigable-trigger
      data-arrow-navigable-item
    />
    <list
      class="hierarchy-nodes-search__list"
    >
      <list-item
        v-for="node in searchedForHierarchyNodes"
        :key="toHash(node)"
        v-arrow-navigable
        class="hierarchy-node-search__list-item"
        tabindex="0"
        data-arrow-navigable-item
        @click="onHierarchyNodeSelected(node)"
        @keydown.enter.prevent="onHierarchyNodeSelected(node)"
      >
        {{ node.name }} ({{ node.code }})
      </list-item>
    </list>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

import List from '@/components/shared/list.vue';
import ListItem from '@/components/shared/list-item.vue';
import Search from '@/components/shared/search.vue';

import { useUtilsStore } from '@/store/utils';
import { useHierarchiesStore } from '@/store/hierarchy-management';
import { storeToRefs } from 'pinia';

export default {
  components: {
    List,
    ListItem,
    Search,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    hierarchyNodes: {
      type: Array,
      required: true,
    },
    selectedHierarchyNode: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['hierarchyNodeSelected'],

  setup(props, { emit }) {
    const { toHash } = useUtilsStore();

    const {
      searchedForHierarchyNodes,
    } = storeToRefs(useHierarchiesStore());

    const {
      storeSearchedForHierarchyNodes,
      resetSearchedForHierarchyNodes,
      queryHierarchyNodes,
      getNodeWithId,
    } = useHierarchiesStore();

    const hierarchyNodeSearchText = ref('');

    const onHierarchyNodeSelected = (theHierarchyNode) => {
      emit('hierarchyNodeSelected', theHierarchyNode);
      hierarchyNodeSearchText.value = theHierarchyNode.name;
      document.activeElement.blur();
    };

    watch(() => hierarchyNodeSearchText.value, () => {
      if (hierarchyNodeSearchText.value.length >= 2) {
        const tempNodes = queryHierarchyNodes(hierarchyNodeSearchText.value);
        const searchedForNodesWithId = tempNodes.map((node) => getNodeWithId(node));
        storeSearchedForHierarchyNodes(searchedForNodesWithId);
      } else {
        resetSearchedForHierarchyNodes();
      }
    });

    watch(() => props.disabled, (isDisabled) => {
      if (isDisabled) {
        hierarchyNodeSearchText.value = '';
      }
    });

    return {
      onHierarchyNodeSelected,
      toHash,
      searchedForHierarchyNodes,
      hierarchyNodeSearchText,
    };
  },
};
</script>

<style scoped>
.hierarchy-node-search {
  position: relative;
  width: 100%;
}

.hierarchy-nodes-search__list {
  position: absolute;
  top: 100%;
  z-index: var(--z-dropdown);
  display: none;
  width: inherit;
  max-height: calc(100vh - 32rem);
  overflow: auto;
  cursor: pointer;
  background: var(--colour-bg-default);
  border: 0.0625rem solid var(--colour-border-light);
  border-top: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.hierarchy-node-search:focus-within > .hierarchy-nodes-search__list {
  display: block;
}

@media (min-width: 46.375rem) {
  .hierarchy-nodes-search__list {
    max-height: calc(100vh - 29rem);
  }
}

@media (min-width: 64rem) {
  .hierarchy-nodes-search__list {
    max-height: calc(100vh - 27rem);
  }
}
</style>
