<template>
  <div
    ref="dropref"
    class="hierarchies-dropdown"
  >
    <dropdown
      v-model:visible="visible"
      v-arrow-navigable
      @keydown.tab="hide"
    >
      <template #activator>
        <dropdown-button
          v-model:active="visible"
          tabindex="0"
          data-arrow-navigable-trigger
          data-arrow-navigable-item
        >
          <dropdown-label
            v-model:active="visible"
          >
            <div
              v-if="hierarchies.length"
              class="hierarchies-dropdown__name"
            >
              <span
                v-if="selectedHierarchy"
                class="hierarchies-dropdown__name hierarchies-dropdown__name--selected"
              >
                {{ selectedHierarchy }}
              </span>
              <span
                v-else
              >
                All hierarchies
              </span>
            </div>
            <div
              v-else
              class="hierarchies-dropdown__name"
            >
              Product has no hierarchies
            </div>
          </dropdown-label>
        </dropdown-button>
      </template>
      <list
        v-if="hierarchies.length"
      >
        <list-item
          tabindex="0"
          data-arrow-navigable-item
          @click="onHierarchySelected(item)"
          @keydown.enter.prevent="onHierarchySelected(item)"
        >
          All hierarchies
        </list-item>
        <list-item
          v-for="item in hierarchies"
          :key="toHash(item)"
          data-arrow-navigable-item
          tabindex="0"
          @click="onHierarchySelected(item)"
          @keydown.enter.prevent="onHierarchySelected(item)"
        >
          <span
            :class="['', {
              'hierarchies-dropdown__name--selected': item === selectedHierarchy
            }]"
          >{{ item }}</span>
        </list-item>
      </list>
    </dropdown>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useOnOutsidePress } from 'vue-composable';

import Dropdown from '@/components/shared/dropdown.vue';
import DropdownButton from '@/components/shared/dropdown-button.vue';
import DropdownLabel from '@/components/shared/dropdown-label.vue';
import List from '@/components/shared/list.vue';
import ListItem from '@/components/shared/list-item.vue';

import { useVisibility } from '@/composables/visibility';

import { useUtilsStore } from '@/store/utils';

export default {
  components: {
    Dropdown,
    DropdownButton,
    DropdownLabel,
    List,
    ListItem,
  },

  props: {
    hierarchies: {
      type: Array,
      required: true,
    },
    selectedHierarchy: {
      type: String,
      default: '',
    },
  },

  emits: ['hierarchySelected'],

  setup(props, { emit }) {
    const { visible, show, hide } = useVisibility();
    const { toHash } = useUtilsStore();

    const dropref = ref(null);
    useOnOutsidePress(dropref, () => hide());
    const onHierarchySelected = (theHierarchy) => {
      console.log('onHierarchySelected');
      emit('hierarchySelected', theHierarchy);
      hide();
    };

    return {
      dropref,
      onHierarchySelected,
      visible,
      toHash,
      show,
      hide,
    };
  },
};
</script>

<style scoped>
.hierarchies-dropdown__name {
  width: 100%;
}

.hierarchies-dropdown__name--selected {
  font-weight: bold;
}
</style>
