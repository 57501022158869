<template>
  <div class="grid grid-view-layout">
    <page-header />
    <div v-if="product">
      <div v-if="isLoadingProductViewPrivilege">
        <!-- nothing, waiting -->
      </div>
      <div v-else-if="product.name === 'All products'">
        <info-page />
      </div>
      <div v-else-if="!hasProductViewPrivilege.hasPrivilege">
        <span>
          <info-page
            title="Missing privilege"
            :description="`You do not have permission to view the preferences for this product (${ product?.name }).`"
          />
        </span>
      </div>
      <div
        v-else-if="hasProductViewPrivilege.hasPrivilege"
        :key="hierarchyNode"
        class="preferences-view"
      >
        <div
          v-if="!canUsePreferencesMenu"
        >
          <error-page
            title="No preferences"
            text="The selected product has no preferences defined for it. Select a different product."
            number=""
          />
        </div>
        <div
          v-else
        >
          <h1
            class="preferences-view__title"
          >
            {{ product?.name }}
          </h1>
          <p
            class="preferences-view__desc"
          >
            Here you can manage preferences for the selected product.
          </p>
          <preferences
            v-show="getComponent === 'preferences'"
            :key="product?.code"
            v-model="preferenceSearchText"
            :is-loading-init="isLoadingPreferenceValues"
            :is-loading-more="isLoadingMorePreferenceValues"
          />
          <preference
            v-if="getComponent === 'preference'"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <info-page />
    </div>
    <modal
      :enabled="isModalEnabled"
      :secondary-button-text="'Okay'"
      :styles="{ secondaryType: 'primary' }"
      title="Something went wrong"
      info="It looks like something went wrong. Please try again later by refreshing the page."
      @secondaryClicked="onModalCancelClicked"
    />
  </div>
</template>

<script>
import {
  ref,
  watch,
  computed,
  onBeforeMount,
  defineAsyncComponent,
} from 'vue';

import { useRoute } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';

import { debounce } from 'lodash';

import PageHeader from '@/components/domain/page-header.vue';
import ErrorPage from '@/components/shared/error-page.vue';
import Preferences from '@/components/domain/preferences/preferences.vue';
import Modal from '@/components/shared/modal.vue';
import InfoPage from '@/components/shared/info-page.vue';

import { usePreferences } from '@/composables/system-management/preferences';

import { useUtilsStore } from '@/store/utils';
import { useHierarchiesStore } from '@/store/hierarchy-management';
import { usePreferenceManagementStore } from '@/store/preference-management';
import { useActorStore } from '@/store/actor';
import { useUserEvaluationStore } from '@/store/user-evaluation';

import { storeToRefs } from 'pinia';

export default {
  components: {
    PageHeader,
    ErrorPage,
    Preferences,
    Modal,
    InfoPage,
    Preference: defineAsyncComponent(() => import(
      '@/components/domain/preferences/preference.vue'
    )),
  },

  async setup() {
    const auth0 = useAuth0();

    const {
      storePreference,
      resetPreference,
    } = usePreferences();

    const {
      preferenceValue,
      preferenceValues,
      preferenceValueList,
      preference,
    } = storeToRefs(usePreferenceManagementStore());

    const {
      queryPreferenceValue,
      storePreferenceValue,
      fetchPreferenceValueList,
      fetchMoreForPreferenceValueList,
    } = usePreferenceManagementStore();

    const {
      product,
      canUsePreferencesMenu,
    } = storeToRefs(useActorStore());

    const {
      hierarchy,
      hierarchies,
      hierarchyNode,
      hierarchyNodes,
      hierarchyNodesDict,
    } = storeToRefs(useHierarchiesStore());

    const {
      fetchHierarchies,
      fetchHierarchyNodes,
      queryHierarchyNodesDict,
      storeHierarchy,
      storeHierarchyNode,
      resetHierarchy,
      resetHierarchyNode,
      resetHierarchyNodes,
      resetSearchedForHierarchyNodes,
    } = useHierarchiesStore();

    const {
      offset,
      limit,
    } = storeToRefs(useUtilsStore());

    const {
      storeOffset,
      storeLimit,
      resetOffset,
    } = useUtilsStore();

    const {
      fetchHasProductViewPrivilege,
      fetchHasProductWritePrivilege,
      fetchHasPreferenceWritePrivilege,
    } = useUserEvaluationStore();

    const {
      hasProductViewPrivilege,
      sortedProducts,
    } = storeToRefs(useUserEvaluationStore());

    const route = useRoute();

    const getComponent = computed(() => {
      const { preferenceCode } = route.query;

      return preferenceCode ? 'preference' : 'preferences';
    });
    const isModalEnabled = ref(false);

    const onModalCancelClicked = () => {
      isModalEnabled.value = false;
    };

    const isLoadingProductViewPrivilege = ref(false);
    const isLoadingPreferenceValues = ref(true);
    const isLoadingMorePreferenceValues = ref(false);

    // eslint-disable-next-line arrow-body-style
    const componentConditionsVerified = computed(() => {
      console.log('componentConditionsVerified', preferenceValue?.value);
      return route.query.preferenceCode
        ? preferenceValue?.value
        : true;
    });
    /*     const componentConditionsVerified = computed(() => {
      console.log('componentConditionsVerified', preferenceValueList?.value);
      return route.query.preferenceCode
        ? preferenceValueList?.value
        : true;
    }); */

    const resetPreferences = () => {
      // Reset data
      resetPreference();
      resetHierarchy();
      resetHierarchyNode();
      resetHierarchyNodes();
    };

    const loadHierarchyNodes = async (hierarchyCode) => {
      const payload = {
        hierarchyCode: hierarchyCode || route.query.hierarchyCode,
        hierarchyNodeCode: 'ROOT',
        hierarchyNodeType: 'ROOTNODE',
        ancestors: false,
      };

      const success = await fetchHierarchyNodes(payload);
      if (!success) {
        isModalEnabled.value = true;
      }
    };

    const loadHierarchies = async () => {
      const payload = {
        productCode: route.query.productCode,
        type: 'preference',
      };

      const success = await fetchHierarchies(payload);
      if (!success) {
        isModalEnabled.value = true;
      }
    };

    const loadPreferenceValues = async () => {
      if (route.query.productCode !== 'All products' && hasProductViewPrivilege.value.hasPrivilege) {
        const payload = {
          productCode: route.query.productCode,
          hierarchyCode: route.query.hierarchyCode,
          ...((route.query.hierarchyCode) && {
            hierarchyCode: route.query.hierarchyCode,
            hierarchyNodeCode: route.query.hierarchyNodeCode || 'ROOT',
            hierarchyNodeType: route.query.hierarchyNodeType || 'ROOTNODE',
          }),
          offset: offset.value,
          limit: limit.value,
        };
        isLoadingPreferenceValues.value = true;
        fetchPreferenceValueList(payload).then(
          () => { isLoadingPreferenceValues.value = false; },
          () => { isModalEnabled.value = true; },
        );
      }
    };

    const setHierarchyNode = (hierarchyNodeId) => {
      const theHierarchyNode = queryHierarchyNodesDict(
        hierarchy.value,
        hierarchyNodeId,
      );
      if (theHierarchyNode) {
        storeHierarchyNode(theHierarchyNode);
      } else {
        resetHierarchyNode();
      }
    };

    const setHierarchy = async (hierarchyCode) => {
      resetPreference();
      resetOffset();
      if (hierarchyCode) {
        await loadHierarchyNodes(hierarchyCode);
        setHierarchyNode(route.query.hierarchyNode);
        storeHierarchy(hierarchyCode);
      } else {
        resetPreferences();
        loadPreferenceValues();
      }
    };

    const loadHasPreferenceWritePrivileges = async () => {
      const auth = auth0;
      const payload = {
        productCode: route.query.productCode,
        privilegeCode: preferenceValue.value.privilegeCode,
        hierarchyNodeType: route.query.hierarchyNodeType,
        userId: auth.user.value['https://accounts.ikea.com/networkid'],
        hierarchyCode: route.query.hierarchyCode,
        hierarchyNodeCode: route.query.hierarchyNodeCode,
      };
      const success = fetchHasPreferenceWritePrivilege(payload);
      if (!success) {
        isModalEnabled.value = true;
      }
    };

    const initPreferences = async () => {
      // Load preference value list.
      await loadPreferenceValues();

      if (route.query.preferenceCode) {
        const result = await queryPreferenceValue({
          preferenceCode: route.query.preferenceCode,
          productCode: route.query.productCode,
        });
        await storePreferenceValue(result);
        loadHasPreferenceWritePrivileges();
      }
      console.log('stored preferenceValue', preferenceValue.value);
      loadHierarchies();

      if (route.query.hierarchyCode) {
        // Set hierarchy.
        await storeHierarchy(route.query.hierarchyCode);

        // Load hierarchy nodes.
        await loadHierarchyNodes();
        setHierarchyNode(route.query.hierarchyNode);
      }
    };

    const loadPrivileges = async () => {
      const state = auth0;
      const payload = {
        productCode: 'DFP',
        hierarchyNodeType: 'PRODUCT',
        privilegeCode: 'ViewProduct',
        userId: state.user.value['https://accounts.ikea.com/networkid'],
        hierarchyCode: 'Product',
        hierarchyNodeCode: route.query.productCode,
      };
      isLoadingProductViewPrivilege.value = true;
      const success = await fetchHasProductViewPrivilege(payload);
      isLoadingProductViewPrivilege.value = false;
      if (!success) {
        isModalEnabled.value = true;
      }
      const payload2 = {
        productCode: 'DFP',
        hierarchyNodeType: 'PRODUCT',
        privilegeCode: 'ManageAnySystemPreferences',
        userId: state.user.value['https://accounts.ikea.com/networkid'],
        hierarchyCode: 'Product',
        hierarchyNodeCode: route.query.productCode,
      };
      const success2 = await fetchHasProductWritePrivilege(payload2);
      if (!success2) {
        isModalEnabled.value = true;
      }
    };

    watch(() => route.query.productCode, async () => {
      console.log('watch route.query.productCode', route.query.productCode);
      resetOffset();
      resetPreferences();
      await loadPrivileges();
      if (route.query.productCode !== 'All products' && hasProductViewPrivilege.value.hasPrivilege) {
        initPreferences();
        loadHierarchies();
      }
    }, { immediate: true });

    watch(
      () => route.query.hierarchyCode, (hierarchyCode) => {
        console.log('watch route.query.hierarchyCode', route.query.hierarchyCode);
        resetSearchedForHierarchyNodes();
        resetOffset();
        setHierarchy(hierarchyCode);
        setHierarchyNode();
        loadPreferenceValues();
      },
    );

    watch(
      () => route.query.hierarchyNode, (hierarchyNodeId) => {
        console.log('watch route.query.hierarchyNode', route.query.hierarchyNode);
        resetOffset();
        setHierarchyNode(hierarchyNodeId);
        loadPreferenceValues();
        loadPrivileges();
      },
    );

    watch(
      () => route.query.preferenceCode, async (preferenceCode) => {
        console.log('watch route.query.preferenceCode', route.query.preferenceCode);
        if (!preferenceCode) {
          loadPrivileges();
          resetPreference();
        } else {
          storePreferenceValue(queryPreferenceValue({ ...route.query }));
          const auth = auth0;
          const payload = {
            productCode: route.query.productCode,
            privilegeCode: preferenceValue.value.privilegeCode,
            hierarchyNodeType: route.query.hierarchyNodeType,
            userId: auth.user.value['https://accounts.ikea.com/networkid'],
            hierarchyCode: route.query.hierarchyCode,
            hierarchyNodeCode: route.query.hierarchyNodeCode,
          };
          const success = fetchHasPreferenceWritePrivilege(payload);
          if (!success) {
            isModalEnabled.value = true;
          }
        }
      },
    );

    const preferenceSearchText = ref('');

    watch(
      () => offset.value, (theOffset) => {
        console.log('offset.value', offset.value);
        if (theOffset && (route.query.productCode !== 'All products')) {
          const payload = {
            productCode: route.query.productCode,
            hierarchyCode: route.query.hierarchyCode,
            preferenceName: preferenceSearchText.value,
            ...((route.query.hierarchyCode) && {
              hierarchyCode: route.query.hierarchyCode,
              hierarchyNodeCode: route.query.hierarchyNodeCode || 'ROOT',
              hierarchyNodeType: route.query.hierarchyNodeType || 'ROOTNODE',
            }),
            offset: offset.value,
            limit: limit.value,
          };
          isLoadingMorePreferenceValues.value = true;
          fetchMoreForPreferenceValueList(payload).then(
            () => { isLoadingMorePreferenceValues.value = false; },
            () => { isModalEnabled.value = true; },
          );
        }
      },
      { deep: true },
    );

    watch(() => preferenceSearchText.value, debounce(() => {
      console.log('preferenceSearchText.value', preferenceSearchText.value);
      if (preferenceSearchText.value.length > 0 && preferenceSearchText.value.length < 3) return;
      const payload = {
        productCode: route.query.productCode,
        hierarchyCode: route.query.hierarchyCode,
        ...((route.query.hierarchyCode) && {
          hierarchyCode: route.query.hierarchyCode,
          hierarchyNodeCode: route.query.hierarchyNodeCode || 'ROOT',
          hierarchyNodeType: route.query.hierarchyNodeType || 'ROOTNODE',
        }),
        offset: 0,
        limit: limit.value,
        preferenceName: (preferenceSearchText.value.length >= 3)
          ? preferenceSearchText.value : '',
      };
      isLoadingPreferenceValues.value = true;
      fetchPreferenceValueList(payload).then(
        () => { isLoadingPreferenceValues.value = false; },
        () => { isModalEnabled.value = true; },
      );
    }, 500, {
      leading: true,
      trailing: true,
    }));

    onBeforeMount(() => {
      storeOffset(0);
      storeLimit(40);
      if (route.query.productCode && route.query.productCode !== 'All products') {
        isLoadingProductViewPrivilege.value = true;
        initPreferences();
        loadPrivileges();
      }
    });

    return {
      getComponent,
      isModalEnabled,
      componentConditionsVerified,
      preference,
      storePreference,
      product,
      sortedProducts,
      preferenceValue,
      preferenceValues,
      preferenceValueList,
      hierarchyNode,
      hierarchyNodes,
      hierarchyNodesDict,
      hierarchy,
      hierarchies,
      hasProductViewPrivilege,
      preferenceSearchText,
      onModalCancelClicked,
      isLoadingProductViewPrivilege,
      isLoadingPreferenceValues,
      isLoadingMorePreferenceValues,
      canUsePreferencesMenu,
    };
  },
};
</script>

<style scoped>
.preferences-view {
  max-height: calc(100vh - 12rem);
  overflow-y: auto;
}

.preferences-view__title {
  padding-top: 2.25rem;
  padding-bottom: 1.375rem;
}

.preferences-view__desc {
  padding-bottom: 0.5rem;
}

@media (min-width: 46.375rem) {
  .preferences-view__desc {
    padding-bottom: 0;
  }
}
</style>
